//
//
//
//
//
//

export default {
  name: 'Content',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      imageUrl: '',
      menuTitle: '',
      menuItems: [],
    }
  },
}
